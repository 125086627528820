import React from 'react';
import PropTypes from 'prop-types';

import Img from 'gatsby-image/withIEPolyfill';

import Link from '../Link/Link';

import {
	ImageWrapperStyled,
	CaptionStyled,
	CaptionedImageWrapperStyled,
	CaptionedImageStyled,
	ImageCaptionStyled,
	types,
	appearances,
	alignments,
} from './styles';

const Image = (props) => {
	const {src, alt, title, type, appearance, alignment, caption, className, maxWidth, optionalLink} = props;

	if (!src) {
		return null;
	}

	const link = optionalLink?.url || null;

	const imageProps = {
		src: src.srcSet ? src.srcSet.split(/[\n ]+/)[0] : src,
		type,
		appearance,
		alignment,
		maxWidth,
	};

	if (caption) {
		return (
			<Link to={link} {...props}>
				<CaptionedImageWrapperStyled alignment={alignment}>
					<CaptionedImageStyled>
						<ImageWrapperStyled className={className} {...imageProps}>
							<Img fluid={src} alt={alt} title={title} />
						</ImageWrapperStyled>
					</CaptionedImageStyled>
					<ImageCaptionStyled>
						<CaptionStyled dangerouslySetInnerHTML={{__html: caption}} />
					</ImageCaptionStyled>
				</CaptionedImageWrapperStyled>
			</Link>
		);
	}

	if (src.src === undefined) {
		return (
			<Link to={link} {...props}>
				<ImageWrapperStyled className={className} {...imageProps} style={{width: '100%'}}>
					<img src={src} alt={alt} title={title} style={{width: 'inherit'}} />
				</ImageWrapperStyled>
			</Link>
		);
	}

	return (
		<Link to={link} {...props}>
			<ImageWrapperStyled className={className} {...imageProps}>
				<Img
					fluid={src}
					alt={alt}
					title={title}
					style={{height: 'inherit'}}
					objectFit="cover"
					objectPosition="50% 50%"
				/>
			</ImageWrapperStyled>
		</Link>
	);
};

export const ImageNonMobile = (props) => {
	const {src, alt, title, type, appearance, alignment, className, maxWidth} = props;

	if (!src) {
		return null;
	}
	const imageProps = {
		src,
		type,
		appearance,
		alignment,
		maxWidth,
	};

	return (
		<ImageWrapperStyled className={className} {...imageProps} style={{width: '100%'}}>
			<img src={src} alt={alt} title={title} style={{width: 'inherit'}} />
		</ImageWrapperStyled>
	);
};

Image.propTypes = {
	src: PropTypes.oneOfType([
		PropTypes.shape({
			srcSet: PropTypes.string,
			src: PropTypes.string,
		}),
		PropTypes.string,
	]).isRequired,
	optionalLink: PropTypes.shape({
		url: PropTypes.string,
	}),
	className: PropTypes.string,
	alt: PropTypes.string,
	title: PropTypes.string,
	type: PropTypes.oneOf(types),
	maxWidth: PropTypes.string,
	appearance: PropTypes.oneOf(appearances),
	alignment: PropTypes.oneOf(alignments),
	caption: PropTypes.string,
};

Image.defaultProps = {
	className: 'ap-image',
	alt: '',
	title: '',
	type: 'default',
	maxWidth: null,
	appearance: 'default',
	alignment: 'center',
	caption: null,
	optionalLink: null,
};

ImageNonMobile.propTypes = {
	src: PropTypes.oneOfType([
		PropTypes.shape({
			srcSet: PropTypes.string,
			src: PropTypes.string,
		}),
		PropTypes.string,
	]).isRequired,
	className: PropTypes.string,
	alt: PropTypes.string,
	title: PropTypes.string,
	type: PropTypes.oneOf(types),
	maxWidth: PropTypes.string,
	appearance: PropTypes.oneOf(appearances),
	alignment: PropTypes.oneOf(alignments),
};

ImageNonMobile.defaultProps = {
	className: 'ap-image',
	alt: '',
	title: '',
	type: 'default',
	maxWidth: null,
	appearance: 'default',
	alignment: 'center',
};

/** @component */
export default Image;
