import React from 'react';
import PropTypes from 'prop-types';
import {SizeMe} from 'react-sizeme';
import TextTruncate from 'react-text-truncate';

const TeaserShortener = ({lines, text}) => {
	// const firstPass = new RegExp(/^[\.,]\s|-/, 'm');
	// const textBlock = text.replace(firstPass, '... ');
	// const secondPass = new RegExp(/(^[a-z])/);
	// const textClean = textBlock.replace(secondPass, '... $1');
	return (
		<SizeMe
			monitorHeight
			render={() => {
				return <TextTruncate line={parseInt(lines, 10)} truncateText="..." text={text} />;
			}}
		/>
	);
};

TeaserShortener.propTypes = {
	lines: PropTypes.string,
	text: PropTypes.string
};

TeaserShortener.defaultProps = {
	lines: '1',
	text: ''
};

export default TeaserShortener;
