import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Observer from '@researchgate/react-intersection-observer';

class ImpressionClickTrackerHOC extends Component {
	handleChange = (event, unobserve) => {
		if (event.isIntersecting && event.intersectionRatio >= 1) {
			this.recordedTimeout = setTimeout(() => {
				// Send logs to server
				const {teaserType, teaserLabel, teaserTitle, teaserLink, teaserPosition} = event.target.dataset;
				window.dataLayer.push({
					event: 'biib_teaserImpression',
					biib_feedContent: teaserType,
					biib_feedContentCat: teaserLabel,
					biib_contentTitle: teaserTitle,
					biib_position: teaserPosition,
					biib_contentUrl: teaserLink
				});

				// console.log('IMPRESSION', {
				// 	...event.target.dataset
				// });
				// Use this for only tracking once per mount
				if (event.isIntersecting) {
					unobserve();
				}
			}, 1000); // Assuming impression is valid only after 1 second
			return;
		}
		clearTimeout(this.recordedTimeout);
	};

	// handleClick = event => {
	// 	const {clickEvent} = this.props;
	// 	// Send logs to server
	// 	console.log(clickEvent || 'UNKNOWN_CLICK_EVENT', {
	// 		...event.currentTarget.dataset
	// 	});
	// };

	render() {
		const {disableViewportTracking, children} = this.props;
		return (
			<Observer
				onChange={this.handleChange}
				// onClick={this.handleClick}
				threshold={1}
				disabled={disableViewportTracking || false}>
				{React.cloneElement(children, {
					// onClick: this.handleClick
				})}
			</Observer>
		);
	}
}

ImpressionClickTrackerHOC.propTypes = {
	clickEvent: PropTypes.func,
	disableViewportTracking: PropTypes.bool,
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
};

ImpressionClickTrackerHOC.defaultProps = {
	clickEvent: null,
	disableViewportTracking: false,
	children: null
};

export default ImpressionClickTrackerHOC;
