import styled, {css} from 'styled-components';

import {sizes} from '../../../styles/variables';
import {imageGradient, media, withUnit, orangeBorder} from '../../../styles/utils';

export const alignments = ['left', 'center', 'right', 'leftBorder'];
export const types = ['default', 'teaser', 'content'];
export const appearances = ['default', 'full-image', 'shadow', 'relatedContent', 'pulled', 'captioned'];

const TeaserImageStyles = css`
	position: absolute;
	top: 0;
	left: 50%;
	transform: translate(-50%, 0);
	opacity: 0;
`;

const TeaserImageWrapperStyles = css`
	position: relative;
	overflow: hidden;

	background-image: url(${(props) => props.src});
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	${imageGradient('half', '180deg')};
`;

const TeaserFullImageWrapperStyles = css`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	${imageGradient('full', '180deg')};
`;

const TeaserRelatedContentWrapperStyles = css`
	${imageGradient('full', '180deg')};
`;

const TeaserPulledWrapperStyles = css`
	${(props) => (props.alignment === 'left' ? `margin-left: 0em;` : ``)};
	${(props) => (props.alignment === 'right' ? `margin-right: 0em;` : ``)};
	${(props) => (props.alignment === 'leftBorder' ? `margin-left: 0em;` : ``)};
`;

export const ImageWrapperStyled = styled.div`
	//max-width: 100%; // fixme: distracts pulled appearance
	${(props) => (props.type === 'teaser' ? TeaserImageWrapperStyles : ``)};
	${(props) => (props.appearance === 'full-image' ? TeaserFullImageWrapperStyles : ``)};
	${(props) => (props.appearance === 'relatedContent' ? TeaserRelatedContentWrapperStyles : ``)};
	${(props) => (props.maxWidth ? `max-width: ${withUnit(props.maxWidth)}; margin: 0 auto;` : ``)};
	img {
		display: block;
	}

	${media.md`
		${(props) => (props.appearance === 'pulled' ? TeaserPulledWrapperStyles : ``)};
	`};
`;

export const ImageStyled = styled.img`
	${(props) => (props.type === 'teaser' ? TeaserImageStyles : ``)};

	display: block;
	max-width: 100%;
	height: auto;

	${(props) => (props.maxWidth ? `max-width: ${withUnit(props.maxWidth)}; margin: 0 auto;` : ``)};
`;

export const CaptionStyled = styled.div`
	position: relative;
	font-size: 0.8em;
	padding-bottom: ${withUnit(sizes.distance.base)};
	${orangeBorder()};

	p {
		margin: 0;
	}
`;

export const CaptionedImageWrapperStyled = styled.div`
	${media.lg`
		display: flex;
		align-items: center;
		${(props) => (props.alignment === 'left' ? `flex-direction: row-reverse;` : ``)};
	`};
`;

export const CaptionedImageStyled = styled.div`
	${media.lg`
		width: 60%;
	`};
`;

export const ImageCaptionStyled = styled.div`
	padding-bottom: ${withUnit(sizes.distance.base)};

	${media.lg`
		padding: ${withUnit(sizes.distance.base)};
		width: 40%;
	`};
`;
